export default {
  api: {
    operationSucceed: '작업 성공',
    operationFailed: '작업 실패',
    errorTip: '에러 메시지',
    errorMessage: '작업이 실패했습니다. 시스템에 이상이 발생했습니다!',
    timeoutMessage: '로그인 시간이 초과되었습니다. 다시 로그인하세요!',
    apiTimeoutMessage:
      '인터페이스 요청 시간이 초과되었습니다. 페이지를 새로고침하고 다시 시도하십시오!',
    apiRequestFailed: '요청 오류입니다. 잠시 후 다시 시도하세요',
    networkException: '네트워크가 비정상입니다',
    networkExceptionMsg: '네트워크가 비정상입니다. 네트워크 연결 상태를 확인하세요!',

    errMsg401: '유저에게 권한이 없습니다(토큰, 유저명, 비밀번호 오류)!',
    errMsg403: '유저가 권한을 부여받았지만 접근은 금지되어 있습니다!',
    errMsg404: '네트워크 요청 오류입니다. 해당 리소스를 찾을 수 없습니다!',
    errMsg405: '네트워크 요청 오류입니다. 허용되지 않은 요청 방법입니다!',
    errMsg408: '네트워크 요청 시간이 초과되었습니다!',
    errMsg500: '서버 오류입니다. 관리자에게 문의하세요!',
    errMsg501: '네트워크가 구현되지 않았습니다!',
    errMsg502: '네트워크 오류!',
    errMsg503: '서비스를 이용할 수 없습니다. 서버가 일시적 과부하 또는 점검 상태입니다!',
    errMsg504: '네트워크 시간 초과!',
    errMsg505: 'HTTP 버전은 해당 요청을 지원하지 않습니다!',
  },
  exception: {
    backLogin: '로그인으로 돌아가기',
    backHome: '홈으로 돌아가기',
    subTitle403: '죄송합니다. 이 페이지를 방문할 수 없습니다.',
    subTitle404: '죄송합니다.  방문한 페이지가 존재하지 않습니다',
    subTitle500: '죄송합니다. 서버 리포트 오류가 발생했습니다.',
    noDataTitle: '현재 페이지에 데이터가 없습니다',
    networkErrorTitle: '네트워크 오류',
    networkErrorSubTitle: '네트워크 연결이 끊겼습니다. 네트워크를 확인하세요!',
  },
  app: {
    title: '에이스몰',
    description: '에이스몰, 게임 충전, Dmen 충전, 토큰 충전',
    tip: '알림',
    copyright: 'Ace Playing',
    icp: '베이징ICP등록번호20009004',
    address: '회사 주소 : 베이징시 차오양구 베이산환 동로 28호',
    account: '내 계정',
    changePwd: '비밀번호 변경',
    chargeRecord: '충전 기록',
    helpCenter: '도움센터',
    chargeNow: '즉시 충전',
  },
  login: {
    email: '이메일',
    verifyCode: '인증 코드',
    password: '비밀번호',
    passwordHolder: '8~30자리 숫자와 알파벳의 조합을 입력하세요',
    loginButton: '로그인',
    freeAccount: '무료 가입',
    freeAccountCreate: '무료로 계정 만들기',
    forgetPassword: '비밀번호를 잊으셨나요?',
    findPassword: '비밀번호 찾기',
    findEmailSent:
      '비밀번호 찾기 메일이 전송되었습니다. 메일에 있는 링크를 클릭하여 비밀번호를 재설정하세요',
    forgetFormTitle: '비밀번호 재설정',
    oldPassword: '기존 비밀번호',
    newPassword: '새로운 비밀번호',
    repeatPassword: '비밀번호를 다시 입력하세요',
    policyCheck: '동의해주세요',
    policy: '《ACE ENTERTAINMENT 개인정보 동의》',
    hadAccount: '기존 계정?',
    backSignIn: '로그인으로 돌아 가기',

    logoutButton: '로그아웃',
    registerButton: '가입',

    otherSignIn: '다른 로그인 방식',

    // notify
    loginSucceed: '로그인 성공',
    logoutSucceed: '현재 계정에서 로그아웃했습니다',
    registerSucceed: '가입 완료',
    changePwdSucceed: '비밀번호 변경 완료',
    resetPwdSucceed: '비밀번호 재설정 완료',

    // ValidateMsg
    emailValidateMsg: '정확한 이메일 주소를 입력하세요',
    passwordValidateMsg: '8~30자리 숫자와 알파벳의 조합을 입력하세요',
    verifySentMsg: '인증코드가 발송되었습니다',
    verifyValidateMsg: '6자리 숫자 인증코드를 입력하세요',
    policyValidateMsg: '등록에 동의한 후에만',
    diffPwdValidateMsg: '두 번 입력한 비밀번호가 일치하지 않습니다',
  },
  help: {
    helpModalTitle: '도움센터',
    helpTipsTitle: '알려드립니다',
    helpTips_1: "이 플랫폼에서 충전하여 얻은 가상 아이템은 'D-MEN : The Defenders'에만 적용됩니다.",
    helpTips_2:
      '주문 시 구매하신 상품의 이름, 가격, 화폐, 수량, 사양, 제품 내역, 주문 절차, 배송 정보, 연락처, 결제 방법 등 정보를 자세히 확인하시기 바랍니다. 개인정보 유출로 인한 피해를 방지하기 위해서 개인정보가 유출되지 않도록 주의를 기울이시기 바랍니다. ',
    serviceTitle: '서비스 설명',
    service_1:
      '이 서비스는 "D-MEN : The Defenders" 애플리케이션을 연동한 유저에게만 적용됩니다. 연동을 하지 않은 유저는 게임에서 연동 작업을 끝내야 이 서비스를 이용할 수 있습니다.',
    service_2:
      '중요 : 계정 입력 후 표시된 계정 정보를 잘 확인하신 후 다음 단계를 진행해주세요!(결제 계정이 틀렸을 경우 환불을 해드릴 수 없으니 양해 부탁드립니다!)',
    service_3:
      '결제가 완료되면 시스템은 해당 가상 아이템을 충전 계정으로 보내드립니다. 게임에서 확인하시기 바랍니다. 충전 30분 후에 구매한 가상 아이템을 받지 못한 경우 아래와 같은 방법으로 고객센터에 연락주시면 조속히 문제를 해결해 드리겠습니다.',
    contactTitle: '고객센터 채널 리스트',
    contactEmail: '고객센터 이메일',
    contactFacebook: 'Facebook 링크',
    contactDiscord: 'Discord 링크',
  },
  charge: {
    title: '상품 리스트',
    description: '에이스몰, 게임 충전, Dmen 충전, 토큰 충전',
    coinSelect: '화폐 선택',
    merchandiseList: '게임 상품 리스트',
    buyNow: '즉시구매',
    quickLimit: '초특가 타임세일',
    buyLimit: '캐릭터당 최대 {limit}개 구매',
    chooseRole: '캐릭터 선택',
    server: '서버',
    serverIdHolder: '서버 이름을 입력하십시오',
    roleID: '캐릭터ID',
    roleIDHolder: '캐릭터 ID를 입력하세요',
    searchResult: '검색 결과',
    searchAgain: '다시 검색',
    addRole: '캐릭터 추가',
    roleInfo: '캐릭터 정보',
    roleLevel: '레벨 {level}',
    gameName: '게임명',
    action: '작업',
    confirmDel: '삭제하시겠습니까?',
    paymentWay: '결제 수단',
    paymentAmount: '결제 금액',
    roleLimit: '이 캐릭터 구매 잔액: ',
    payNow: '즉시 결제',
    payJumpTips: '곧 제3자 결제 플랫폼으로 이동합니다. 잠시만 기다리세요..',
    paid: '결제 완료',
  },
  orders: {
    title: '주문 리스트',
    description: '주문 리스트',
    ordersTitle: '주문 리스트',
    searchDate: '날짜 조회',
    selectGame: '게임 선택',
    allGame: '모든 게임',
    orderNo: '주문 번호',
    gameName: '게임명',
    merchandiseName: '상품명',
    merchandiseDetail: '항목 세부 정보',
    orderAmount: '주문 금액',
    createAt: '생성 시간',
    orderStatus: '주문 상태',
    statusGoing: '결제 중',
    statusSucceed: '결제 성공',
    statusUnknown: '지불 결과를 보려면 재충전 기록으로 이동하십시오.',
    statusFailed: '결제 실패',
    action: '작업',
    viewDetail: '자세히 보기',
    detailTitle: '주문 내역',
    backToOrders: '주문 리스트로 돌아가기',
    orderInfo: '주문 정보',
    merchandiseInfo: '상품 정보',
    price: '단가',
    quantity: '수량',
    discount: '할인',
    payResult: '결제 결과',
    viewOrder: '주문 확인',
  },
  order: {
    title: '주문 내역',
    description: '주문 내역',
  },
  notFound: {
    title: '404',
    description: '404',
  },
  result: {
    title: '결제 결과',
    description: '결제 결과',
  },
};
